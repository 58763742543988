.img {
  // display: block;
  width: 100%;
  // width: calc(100% - 24px);
  height: 100%;
  object-fit: cover;
  // object-position: 20% 1px;

  display: block;
  // max-width: 100%;

  //before presentation
  // width: 100%;
  // height: 100%;
  // object-fit: cover;
}

.letter {
  width: 100%;
  height: 100%;
  font-family: Nunito;
  font-size: 56px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0em;
  text-align: center;
  // margin:  auto;

  color: var(--white-text-color);
  background-color: #7365c3;
  display: inline-grid;

  justify-content: center;
  align-content: center;
}

.letter.in-small-avatar {
  font-size: 18px;
  line-height: 1;

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
}

.letter.in-review {
  font-size: 16px;
  line-height: 1;
}
