@import 'theme/abstract/_mixins.scss';

.container {
  width: 100%;
}

.text {
  text-align: center;
}

.button {
  margin: 32px auto 0;
}
