@import '../../theme/abstract/mixins';

.wrapper {
  padding: 60px 16px 60px;
  @media screen and (min-width: 768px) {
    padding: 60px;
  }
}

.text {
  color: var(--black-text-color);
  /* основний текст */
  @include text-styles(Nunito, 14px, 400);
  text-align: center;
  padding-bottom: 33px;
  @media screen and (min-width: 768px) {
    @include text-styles(Nunito, 16px, 700);
  }
}

.wrapperButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
