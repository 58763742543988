@import 'theme/abstract/mixins';

.list {
  list-style: none;
  margin: 0;
  align-items: baseline;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding-left: 14px;
  }

  @media screen and (min-width: 1280px) {
    margin: 0;
    gap: 32px;
  }
}

.list_first {
  list-style: none;
  margin: 0;
  padding-left: 15px;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding-left: 10px;
  }

  @media screen and (min-width: 1280px) {
    // display: flex;
    margin: 0;
    gap: 32px;
  }
}

// .item:not(:last-child) {
// margin-bottom: 20px;

// @media screen and (min-width: 768px) {
// margin-top: 0px;
// margin-bottom: 0px;
// }

// @media screen and (min-width: 1280px) {
//   margin-bottom: 0;
//   margin-right: 40px;
// }
// }

.item {
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    margin-bottom: 0px;
  }
}

.link {
  color: var(--black-text-color);
  font-family: Amatic SC;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.96px;

  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    color: var(--accent-color);
  }

  &:global(.active) {
    color: var(--accent-color);
  }

  @media screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 32px; /* 145.455% */
    letter-spacing: 0.66px;
  }

  @media screen and (min-width: 1280px) {
    /* Menu Desktop */

    font-size: 32px;
    line-height: 36px; /* 150% */
    letter-spacing: 0.72px;
  }
}

.wrap {
  display: flex;
  justify-content: baseline;
  align-items: center;
  text-decoration: none;
  margin: 0 5px;
  color: var(--black-text-color);
  /* основний текст */
  @include text-styles;
}
