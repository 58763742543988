.to-top-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border: none;
  padding: 0;
  background-color: transparent;

  position: fixed;
  z-index: 100;
  // top: calc(100vh - 100px);
  bottom: 32px;
  right: 16px;

  opacity: 0;
  animation: fadeIn 250ms ease-in forwards;

  &.fade-out {
    animation: fadeOut 250ms ease-in forwards;
  }
}

// .to-top-icon {
//   fill: #f3a610;
//   cursor: pointer;
//   // &:hover {
//   //   fill: #f39610;
//   //   // filter: drop-shadow(0px 1px 5px rgba(255, 193, 7, 0.5));
//   // }
// }

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
