@import 'theme/abstract/_mixins.scss';
@import 'theme/abstract/_mixins.scss';

.text {
  @include text-styles;
}

.text--regular {
  color: var(--black-text-color);
}

.text--centered {
  text-align: center;
  color: var(--gray-color);
  margin: 12px 0;
}

.text.text--divider {
  position: relative;
  display: inline-block;
  // width: 100%;
  margin: 12px auto;
  padding: 0 16px;
  text-align: center;

  color: var(--gray-color);
  background-color: var(--white-bg-color);

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 50%;
    right: 50%;
    transform: translateY(-50%);
    width: calc(100vw / 2 - 16px);
    height: 1px;
    background-color: var(--gray-color);

    @media screen and (min-width: 480px) {
      width: calc(480px / 2 - 16px);
    }
    @media screen and (min-width: 1280px) {
      width: calc(606px / 2 - 16px);
    }
  }
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    width: calc(100vw / 2 - 16px);
    height: 1px;
    background-color: var(--gray-color);

    @media screen and (min-width: 480px) {
      width: calc(480px / 2 - 16px);
    }
    @media screen and (min-width: 1280px) {
      width: calc(606px / 2 - 16px);
    }
  }
}

.text--error {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: var(--error-color);
}
