@import 'theme/abstract/_mixins.scss';

.text-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 20px;

  @media screen and (min-width: 480px) {
    max-width: 448px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    padding-bottom: 32px;
  }
}

.text-sum {
  color: var(--black-text-color);
  @include text-styles('Nunito', 16px, normal, 700);
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 20px;
}

.total {
  color: var(--black-text-color);
  @include text-styles('Nunito', 16px, 700);
  // padding-right: 16px;
}

.list {
  padding-bottom: 32px;

  @media screen and (min-width: 480px) {
    max-width: 448px;
    margin: 0 auto;
  }
}

.item:not(:last-child) {
  margin-bottom: 20px;
  @media screen and (min-width: 768px) {
    margin-bottom: 28px;
  }
}
