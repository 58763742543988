@import './theme/abstract/_variables.scss';
@import './theme/abstract/_mixins.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  /* max-width: 1280px; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Nunito, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

img {
  display: block;
  height: auto;
}

button {
  cursor: pointer;
}

li {
  list-style: none;
}

a {
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--link-color);
  text-decoration-line: none;
}

/* 
.container {
	padding-left: 15px;
	padding-right: 15px;
	margin-left: auto;
	margin-right: auto;
}

@media screen and (min-width: 480px) {
	.container {
		width: 480px;
	}
}

@media screen and (min-width: 768px) {
	.container {
		width: 768px;
	}
}

@media screen and (min-width: 1280px) {
	.container {
		width: 1200px;
	}
} */
