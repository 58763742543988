@import 'theme/abstract/_mixins.scss';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 736px;
    // padding: 0 16px;
    margin: 0 auto;
  }
  @media screen and (min-width: 1280px) {
    max-width: 1280px;
  }
}

.heading {
  width: 100%;
  padding: 32px 0 24px 0;

  @include text-styles(Amatic SC, 32px, 700);
  letter-spacing: 0.96px;
  text-align: center;

  @media screen and (min-width: 768px) {
    padding: 32px 0 32px 0;
  }
}

.h1 {
  font-size: 48px;
}

.h2 {
  font-size: 32px;
  text-align: center;
  @media screen and (min-width: 768px) {
    font-size: 40px;
    line-height: 1.5;
  }
}

.h2.with-goback {
  margin-left: 48px;
  margin-right: 48px;
}

.h3 {
  @include text-styles(Nunito, 16px, 700);
  letter-spacing: 0;
  text-align: start;
  padding: 8px 0;
}

// .h4 {

// }
