.backdrop {
  position: fixed;
  padding: 0 20;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  // overflow-x: auto;
  // background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);
  z-index: 1200;
}
.modal::-webkit-scrollbar {
  display: none;
}

.modal {
  position: absolute;
  top: 112px;
  left: 50%;
  max-width: 420px;
  width: calc(100% - 32px);
  max-height: calc(100vh - 128px);
  transform: translate(-50%, 0);
  border-radius: 16px;
  // width: 87.5%;
  // padding: 0 16px 32px; // якщо вставиш компонент Heading (заголовок), там вже буде верхній паддінг 32px.
  border: 1px solid var(--Stroke, #f0f0f0);
  background: var(--White, #fff);
  // overflow-y: hidden;
  overflow-x: auto;

  /* Shadow for cards */
  box-shadow: var(--box-shadow);
  @media screen and (min-width: 768px) {
    width: 480px;
  }
  @media screen and (min-width: 1280px) {
    width: 606px;
  }

  @media screen and (min-height: 800px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.modal--centered {
  top: 50%;
  transform: translate(-50%, -50%);
}
