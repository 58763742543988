:root {
  --white-bg-color: #ffffff;

  --black-text-color: #191c2c;
  --white-text-color: #fefefe;
  --grey-text-color: #a3a5a4;

  --accent-color: #f3a610;
  --gray-color: #a3a5a4;
  --input_stroke: #7b7b7b;
  --link-color: #0a84ff;
  --link-accent-color: #0c6ac8;
  --error-color: #f84147;

  --font-family: 'Nunito', sans-serif;
  --font-size: 0.875rem;
  --font-regular: 400;
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;
  --font-style: normal;

  --line-height: normal;

  --box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);

  --border-primary: 1px solid var(--input_stroke);
  --border-accent: 1px solid var(--accent-color);
  --border-gray: 1px solid var(--gray-color);
  --border-error: 1px solid var(--error-color);

  --border-radius-primery: 1rem;
  --border-radius-secondary: 16px;

  --md-pc: 1200px;
  --md-tablet: 991.98px;
  --md-mobile: 767.98px;
  --md-mobile-small: 479.98px;

  --animation-duration: 500ms;

  --desktop-items-per-row: 3;
  --tablet-items-per-row: 2;
  --mobile-items-per-row: 1;
}
