@import '../../theme/abstract/mixins';
@import '../../theme/base/base';

.container {
  position: fixed;
  border-radius: var(--border-radius-primery);
  box-shadow: var(--box-shadow);
  z-index: 1000;
  text-align: center;
  background-color: var(--gray-color);
  color: var(--white-text-color);

  top: 50px;
  right: 10px;
  padding: 10px;
  @include text-styles('Nunito', 16px, 700);

  @media screen and (min-width: 480px) and (max-width: 767px) {
    top: 50px;
    right: 50px;
    padding: 20px;
    @include text-styles('Nunito', 20px, 700);
  }
  @media screen and (min-width: 768px) and (max-width: 1279px) {
    top: 70px;
    right: 70px;
    padding: 30px;
    @include text-styles('Nunito', 24px, 700);
  }
  @media screen and (min-width: 1280px) {
    top: 80px;
    right: 80px;
    padding: 40px;
    @include text-styles('Nunito', 26px, 700);
    // color: var(--input_stroke);
    // background-color: var(--white-bg-color);
  }
}

.buttonsContainer {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
