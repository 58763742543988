@import 'theme/abstract/_mixins.scss';

.header {
  box-sizing: border-box;
  // max-width: 100%;
  margin-left: -16px;
  margin-right: -16px;

  padding-top: 10px;
  padding-bottom: 10px;
  background: rgba(255, 255, 255);
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
}

.container {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 768px) {
    width: 768px;
    align-items: center;
  }
  @media screen and (min-width: 1280px) {
    width: 1280px;
    padding-left: 60px;
    padding-right: 12px;
    align-items: center;
  }
}

.logo {
  padding-left: 75px;
  padding-right: 35px;
  @media screen and (min-width: 768px) {
    padding: 16px 0;
  }
}

.boxBasket {
  display: flex;
  gap: 12px;
  @media screen and (min-width: 768px) {
    padding-left: 8px;
    gap: 12px;
  }
  @media screen and (min-width: 1280px) {
    gap: 32px;
    padding-top: 0;
  }
}

.userIcon {
  display: flex;
  justify-content: center;
  align-items: center;

  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;

  @media screen and (min-width: 768px) and (max-width: 1279px) {
    // padding-top: 32px;
  }
}

.userIcon:hover * {
  fill: var(--accent-color);
}

.buttonBasket {
  position: relative;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.ellips {
  position: absolute;
  top: 60%;
  left: 60%;
}

.ellipsSpan {
  position: absolute;
  left: 30%;
  width: 7px;
  color: var(--white-text-color);
  @include text-styles(Nunito, 12px);
}

.ellipsSpan1 {
  position: absolute;
  left: 3%;
  width: 15px;
  color: var(--white-text-color);
  @include text-styles(Nunito, 12px);
}

.heart_icon {
  fill: transparent;
  stroke: rgba(243, 166, 16, 1);
  transition: fill 0.3s ease 0s;
}

.heart_icon:hover {
  fill: rgba(243, 166, 16, 1);
  stroke: rgba(243, 166, 16, 1);
}
