@import 'theme/abstract/_mixins.scss';

.wrapper {
  position: relative;
  min-width: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  fill: transparent;
  background-color: transparent;
}

.wrapper:hover {
  fill: #fefefe;
  background-color: rgba(51, 51, 51, 0.6);
}

.wrapper:focus {
  fill: #fefefe;
  background-color: rgba(51, 51, 51, 0.6);
}

.avatar {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
}

.avatarEdit {
  display: block;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
}

.cameraIcon {
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 50%;
  bottom: 0px;
  padding-bottom: 5px;
  padding-top: 7px;
  padding-left: auto;
  padding-right: auto;
  height: 30px;
  width: 100%;
  transform: translate(-50%, 0);
  fill: inherit;
  background-color: inherit;
  //   visibility: hidden;
  //   outline: none;
  //   border: none;
  // padding: 0;
  // margin: 0;
  //   background-color: transparent;
}

// .cameraIcon:hover * {
//   fill: var(--accent-color);
// }

.mainText {
  color: var(--black-text-color);
  font-family: '' Nunito ', sans-serif';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding: 60px 22px 33px 23px;
}

.text {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #a3a5a4;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding-bottom: 40px;
  gap: 24px;
  margin-top: 8px;
  width: 100%;
  margin-top: 32px;
  margin-right: auto;
  margin-left: auto;
}

.fileInputLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 40px;
  padding: 0 16px;
  @include text-styles(Nunito, 14px, 700, 19px);
  border: none;

  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  color: var(--white-text-color);
  border: var(--border-accent);
  background-color: var(--accent-color);

  &:hover,
  &:focus,
  &:active {
    color: var(--white-text-color);
    // border: none;

    background-color: #f39610;
    box-shadow: 0px 1px 2px 0px rgba(255, 193, 7, 0.5);
  }

  &:focus {
    outline: none;
  }

  &:disabled,
  &:focus:disabled,
  &:hover:disabled,
  &:active:disabled {
    color: rgba(254, 254, 254, 0.7);
    background-color: transparent;
    border: 1px solid rgba(254, 254, 254, 0.7);
  }
}

.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.fileInput {
  visibility: hidden;
}

.icon {
  width: 24px;
  height: 24px;
  fill: var(--black-text-color);
  stroke: var(--black-text-color);

  // &--reverse {
  //   transform: rotate(180deg);
  // }
}

.fileInputLabel:hover .icon {
  fill: #fff;
  fill-opacity: 1;
}

.fileInputLabel:disabled .icon,
.fileInputLabel:focus:disabled .icon,
.fileInputLabel:hover:disabled .icon,
.fileInputLabel:active:disabled .icon {
  color: rgba(254, 254, 254, 0.7);
  background-color: transparent;
  fill: var(--white-text-color);
  fill-opacity: 0.4;
}
