.layout {
  //   max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding-right: 16px;
  padding-left: 16px;
  overflow-x: hidden;
  //   padding: 0 15px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
