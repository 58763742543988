.wrapper {
  padding: 0 16px 32px;
  @media screen and (min-width: 768px) {
    padding: 28px 60px 60px;
  }
}

// .wrapperButton {
//   &:last-child {
//     padding-top: 20px;
//   }
// }
