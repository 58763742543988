@import '../../theme/abstract/mixins';

.cookiesBanner {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  width: 100%;
  background-color: var(--input_stroke);
  margin: 0px -16px;
  padding: 15px 0px;
  // @media screen and (min-width: 1440px) {
  //   max-width: 1280px;
  // }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media screen and (min-width: 480px) {
    flex-direction: row;
  }
  @media screen and (min-width: 768px) {
    max-width: 720px;
  }
  @media screen and (min-width: 1440px) {
    max-width: 1200px;
    gap: 25px;
    align-items: center;
  }
}

.bannerText {
  color: var(--white-text-color);
}

.privacyLink {
  text-decoration: underline;
  color: var(--white-text-color);
  /* основний текст */
  @include text-styles;

  &:hover,
  &:focus {
    color: var(--accent-color);
  }

  &:global(.active) {
    color: var(--accent-color);
  }
}

.container .agree-button {
  font-size: 14px;
  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
}
