* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-width: 320px;
}

body {
  color: var(--color-font);
  background: var(--color-bg);
  font-family: var(--font-family);
  font-weight: var(--font-regular);
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  &::-webkit-scrollbar {
    width: 7px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: var(--color-yellow);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--color-blue-dark);

    transition: background 0.3s ease;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--color-gray-dark);
  }
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

input,
button,
textarea {
  font-family: var(--font-family);
  font-size: inherit;
}

button {
  cursor: pointer;
  color: inherit;
  background-color: inherit;
}

a {
  // color: inherit;
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

h1 {
  text-align: center;
  margin: 1rem;
  font-size: 2.5rem;
  letter-spacing: 1.8px;
  font-weight: var(--font-bold);
}

h2 {
  text-align: center;
  font-size: 2rem;
  letter-spacing: 1.3px;
  font-weight: var(--font-semi-bold);
}

h3 {
  font-family: Amatic SC;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: var(--black-text-color);
}

body.lock {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}

#root {
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}

@supports (overflow: clip) {
  #root {
    overflow: clip;
  }
}

#root > main {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

#root > * {
  min-width: 0;
}

[class*='__container'] {
  max-width: 1300px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
