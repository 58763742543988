.offline-notification {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ff9800;
  color: white;
  text-align: center;
  padding: 1em;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
