@import 'theme/abstract/_mixins.scss';

.container {
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 288px;
  min-width: 240px;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
  opacity: 0;
  cursor: pointer;

  animation-name: fade-in;
  animation-timing-function: ease-in;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

.title {
  @include text-styles(Amatic SC, 32px, 700);
  letter-spacing: 0.96px;
  text-align: center;
  color: #fff;
}

.message {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  text-align: start;
}

.success {
  background-color: #68baee;
}

.info {
  background-color: #68baee;
}

.warning {
  background-color: #f3a610;
}

.error {
  background-color: #f6371e;
}

.fade-out {
  animation-name: fade-out;
  animation-timing-function: ease-out;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  85% {
    opacity: 0.5;
    transform: translateX(-10%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  15% {
    opacity: 0.5;
    transform: translateX(-10%);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}
