.line-container {
  position: relative;
  width: 100%;
  height: 6px;
  margin-bottom: -6px;
}

.line-container .line {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: radial-gradient(
    50% 49.53% at 50% -22.43%,
    #f3a610 0%,
    rgba(243, 166, 16, 0) 100%
  );
}

.line-container .line.admin {
  background: radial-gradient(
    50% 49.53% at 50% -22.43%,
    #68baee 0%,
    rgba(243, 166, 16, 0) 100%
  );
}
