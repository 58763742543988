@import 'theme/abstract/_mixins.scss';

.wrapper-allproduct {
  display: flex;
  justify-content: space-between;
}

.wrapper-quantityblock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
  margin-left: 12px;
  width: 190px;
}

.wrapper-imageblock {
  width: 80px;
  height: 80px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: var(--box-shadow);
}

.wrapperButton {
  margin-left: auto;
  width: 256px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 60px;
  gap: 8px;
}

.text-product {
  color: var(--black-text-color);
  /* Additional */
  @include text-styles(Nunito, 12px);
}

.quantityButtoms-container {
  padding-top: 20px;
}

.quantity-container {
  display: flex;
  align-items: center;
  background-color: transparent;
}

.plus-button {
  display: flex;
  align-items: center;
  justify-content: center;

  outline: none;
  border: none;

  padding: 0;
  margin: 0;
  background-color: transparent;

  &:disabled {
    opacity: 0.5;
  }
}

.minus-button {
  display: flex;
  align-items: center;
  justify-content: center;

  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;

  &:disabled {
    opacity: 0.5;
  }
}

.value {
  color: var(--black-text-color);
  /* основний текст */
  @include text-styles;
  padding-right: 8px;
  padding-left: 8px;
}

.custom-value {
  font-size: 1rem;
  font-weight: 700;
}

.wrapper-basketblock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: auto;
  width: 90px;
}

.total {
  color: var(--black-text-color);
  // padding-top: 38px;
  @include text-styles;
  margin-bottom: 3px;
}
