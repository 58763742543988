@import '../../theme/abstract/mixins';

.footer {
  position: relative;
  background: var(--footer-bg, #f4f4f4);
  // ****************
  margin-left: -16px;
  margin-right: -16px;
  // margin-top: auto;
  @media screen and (min-width: 768px) {
    padding-top: 32px;
    padding-bottom: 12px;
  }
}

.footerContainer {
  position: relative;
  // width: 100%;
  padding: 10px 16px 12px 16px;
  max-width: 1280px;
  margin: 0 auto 0 auto;
}
.footerItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: var(--border-gray);
  @media screen and (min-width: 768px) {
    border-bottom: inherit;
  }
}
.footerTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.label {
  color: var(--black-text-color);
  /* основний текст */
  @include text-styles;
  &.opened {
    color: var(--gray-color);
  }
  @media screen and (min-width: 768px) {
    color: var(--gray-color);
  }
}

.labelTablet {
  color: var(--gray-color);
  margin-bottom: 8px;
}

.footerLinks {
  display: flex;
  flex-direction: column;
  gap: 13px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
}

.linksList {
  width: 100%;
  list-style: none;
  padding: 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.link {
  text-decoration: none;
  color: var(--black-text-color);
  /* основний текст */
  @include text-styles;

  &:hover,
  &:focus {
    color: var(--accent-color);
  }

  &:global(.active) {
    color: var(--accent-color);
  }
}

.footerBox {
  padding-top: 20px;
  padding-bottom: 16px;
}

.footerIconsContainers {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.opened {
    transform: rotate(180deg);
  }
}

.icon {
  background: none;
  padding: 0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  fill: #818185;
  stroke: #818185;
  stroke-width: 0%;

  &:hover,
  &:focus,
  &:active {
    color: var(--accent-color);
    fill: var(--accent-color);
    stroke: var(--accent-color);
    background-color: transparent;
  }
}

.socialIcons {
  fill: #818185;
  display: flex;
  gap: 14px;
}

.bankIcons {
  display: flex;
  gap: 16px;
}

.copy {
  margin-top: 20px;
  color: var(--black-text-color);
  font-size: 12px;
  text-align: center;
}
.logoBee {
  margin-top: 20px;
  color: #040404;
  font-size: 12px;
  text-align: center;
}

.footerBottom {
  @media screen and (min-width: 768px) {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
