@import 'theme/abstract/_mixins.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  max-width: 288px;
  height: 40px;
  padding: 0 16px;
  // margin: 0 auto;

  @include text-styles(Nunito, 14px, 700, 19px);

  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease 0s;

  &:hover,
  &:focus,
  &:active {
    background-color: #f39610;
    box-shadow: 0px 1px 5px 0px rgba(255, 193, 7, 0.5);
  }

  &:focus {
    outline: none;
  }

  &:disabled,
  &:focus:disabled,
  &:hover:disabled,
  &:active:disabled {
    background-color: rgba(243, 166, 16, 0.5);
    cursor: not-allowed;
  }

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
}

.button_md {
  min-width: 120px;

  &:hover,
  &focus,
  &:active {
    box-shadow: 0px 2px 5px 1px rgba(255, 193, 7, 0.4);
  }
}

.button_lg {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  margin: 0 auto;
  &:hover,
  &focus,
  &:active {
    box-shadow: 0px 2px 5px 1px rgba(255, 193, 7, 0.4);
  }
}

.button_sm {
  // font-weight: 400;
  padding: 0;
}

.button_primary {
  color: var(--white-text-color);
  background-color: var(--accent-color);
}

.button_outlined {
  color: var(--accent-color);
  border: var(--border-accent);
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    color: var(--white-text-color);
    border: none;
    background-color: var(--accent-color);
  }

  &:disabled,
  &:focus:disabled,
  &:hover:disabled,
  &:active:disabled {
    color: rgba(254, 254, 254, 0.7);
    background-color: transparent;
    border: 1px solid rgba(254, 254, 254, 0.7);
  }
}

.button_outlinedGrey {
  color: var(--input_stroke);
  border: var(--border-primary);
  background-color: transparent;
  &:hover,
  &:focus,
  &:active {
    color: var(--white-text-color);
    border: none;
    background-color: var(--accent-color);
  }

  &:disabled,
  &:focus:disabled,
  &:hover:disabled,
  &:active:disabled {
    color: rgba(254, 254, 254, 0.7);
    background-color: transparent;
    border: 1px solid rgba(254, 254, 254, 0.7);
  }
}

.button_adding,
.button_settings {
  justify-content: flex-start;
  align-self: flex-start;
  column-gap: 16px;
  height: 24px;
  padding: 0 0 0 0;

  font-weight: 400;

  color: var(--gray-color);
  fill: var(--black-text-color);
  stroke: var(--black-text-color);
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    color: var(--accent-color);
    fill: var(--accent-color);
    stroke: var(--accent-color);
    background-color: transparent;
    box-shadow: none;
  }

  &:disabled,
  &:focus:disabled,
  &:hover:disabled,
  &:active:disabled {
    color: rgba(254, 254, 254, 0.7);
    background-color: transparent;
  }
}

.button_goBack {
  position: absolute;
  top: 32px;
  left: 0;
  min-width: 40px;
  padding: 0;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    box-shadow: none;
  }

  // @media screen and (min-width: 768px) {
  //   left: 16px;
  // }
}

.button_sort {
  justify-content: space-between;
  // width: 138px;
  padding: 0 16px;

  font-weight: 400;
  color: var(--black-text-color);
  background-color: transparent;
  border: var(--border-primary);
  border-radius: 8px;

  &:hover,
  &:focus,
  &:active {
    border: none;
    background-color: var(--accent-color);
    color: var(--white-text-color);

    & .icon {
      stroke: var(--white-text-color);
    }
  }
}

.button_lg.button_close {
  width: 32px;
  height: 32px;
  stroke: #2b2a29;
  // fill: #2b2a29;
  background-color: transparent;
  padding: 0;
  &:hover,
  &:focus,
  &:active {
    stroke: var(--accent-color);
    // fill: #f3a610;
    box-shadow: none;
  }
}

.button_md.button_close {
  min-width: 16px;
  width: 16px;
  height: 16px;

  position: absolute;
  top: 16px;
  right: 16px;

  padding: 0;
  margin: 0;
  background-color: transparent;
  stroke: var(--black-text-color);

  &:hover,
  &:focus,
  &:active {
    stroke: var(--accent-color);
    box-shadow: none;
  }
}

.button_menu {
  min-width: 32px;
  height: 32px;
  padding: 0;
  fill: #2b2a29;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    fill: var(--accent-color);
    background-color: transparent;
    box-shadow: none;
  }
}

.icon {
  width: 24px;
  height: 24px;
  fill: var(--black-text-color);
  stroke: var(--black-text-color);

  // &--reverse {
  //   transform: rotate(180deg);
  // }
}

.button:hover .icon {
  fill: #fff;
  fill-opacity: 1;
}

.button:disabled .icon,
.button:focus:disabled .icon,
.button:hover:disabled .icon,
.button:active:disabled .icon {
  color: rgba(254, 254, 254, 0.7);
  background-color: transparent;
  fill: var(--white-text-color);
  fill-opacity: 0.4;
}

.admin-button {
  // background-color: #0066cc;
  background-color: #0a84ff;
  filter: saturate(75%);

  &:hover,
  &:focus,
  &:active {
    // background-color: #004e9c;
    background-color: #0066cc;
    box-shadow: none;
  }

  &:disabled {
    background-color: rgba(0, 102, 204, 0.5);
  }

  &:hover:disabled {
    background-color: rgba(0, 102, 204, 0.5);
  }
}
