@mixin font($size, $weight, $color) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

@mixin text-styles(
  $family: '' Nunito ', sans-serif',
  $size: 14px,
  $weight: 400,
  $height: normal
) {
  font-family: $family;
  font-size: $size;
  font-style: normal;
  font-weight: $weight;
  line-height: $height;
}
