.mobileMenu {
  position: absolute;
  z-index: 100;
  display: block;
  box-sizing: border-box;

  min-width: 320px;
  // max-width: 100%;
  width: 100%;
  height: calc(100vh - 85px);
  padding: 32px 16px 64px 15px;

  top: 85px;
  left: 50%;
  transform: translateX(-50%);

  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);
  box-shadow: inset 0px 4px 24px rgba(0, 0, 0, 0.2);

  animation-name: burgerDropDown;
  animation-duration: 500ms;

  overflow-y: auto;

  @media screen and (min-width: 768px) {
    // padding: 24px 32px;
  }

  @media screen and (min-width: 1280px) {
    width: 1280px;
    // padding: 20px 16px;
  }
}

.mobileMenuDark {
  background-color: #2b3e51;
}

// .container {
//   box-sizing: border-box;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   max-width: 100%;
//   padding-left: 16px;
//   padding-right: 16px;
//   padding-top: 10px;
//   padding-bottom: 10px;

//   @media screen and (min-width: 768px) {
//     max-width: 768px;
//   }

//   @media screen and (min-width: 1280px) {
//     max-width: 1280px;
//   }
// }

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: rgba(255, 255, 255);
  box-shadow: var(--box-shadow);

  backdrop-filter: blur(2px);
}

.logo {
  padding-left: 75px;
  padding-right: 35px;
}

.closeMobileMenu {
  animation-name: burgerDropOut;
  animation-duration: 500ms;
}

@keyframes burgerDropDown {
  0% {
    transform: translate(-50%, -100%);
  }
  100% {
    transform: translate(-50%, 0%);
  }
}

@keyframes burgerDropOut {
  0% {
    transform: translate(-50%, 0%);
  }
  100% {
    transform: translate(-50%, -100%);
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.boxBasket {
  display: flex;
  gap: 12px;
}
