@import '../../theme/abstract/mixins';

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-left: auto;
  //  border: solid red 1px;

  @media screen and (min-width: 1280px) {
    width: 100%;
    justify-content: space-between;
    margin-left: 16px;
    gap: 14px;
  }
}



.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  margin-left: auto;
  margin-right: 5px;
}

.burger {
  cursor: pointer;

  @media screen and (min-width: 768px) {
    margin-left: 22px;
  }
}
.userNavWrap {
  display: flex;
  justify-content: baseline;
  align-items: center;
  text-decoration: none;
  margin: 0 5px;
  color: var(--black-text-color);
  /* основний текст */
  @include text-styles;
}
.authLink {
  display: inline-block;
  text-decoration: none;
  margin: 0 5px;
  color: var(--black-text-color);
  /* основний текст */
  @include text-styles;

  &:global(.active) {
    font-weight: bold;
    color: var(--accent-color);
  }
}
